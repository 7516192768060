// const { EvmChain } = require("@moralisweb3/common-evm-utils");


export const network =
{
    "56": {  
    
        chain:"Binance",
        rpcUrl:"https://bsc-dataseed.binance.org/",
        networkVersion:'56',
        chainId :'56',
        currencySymbol:"BNB",
        tokenSymbol:"CAKE",
        siteUrl:"https://bscscan.com/",
        // tokenAddress:"0x565654383Ec9F2d6184b0907cAE7AF150Ead2658",
        tokenAddress:"0x7CAB80ce0E55F46378E493B584eE61aD68878f11",
      
        singleContract :"0xc80299d55412071f702772Df6283a8aE1598250A".toLowerCase(),
        multipleContract:"0x672A391f531bcfdb9d6BE83270449bC4EC4b1350".toLowerCase(),
        tradeContract :("0xD59aeE8E76Aa7c86314974d576ADe6d70Efd3090").toLowerCase(),
        adminAddress:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
        chainName : "BSC",
        label : "Binance",
        value : "Binance",
    },  


}